import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import configuration from "configuration";
import { WebSocketService } from "lib/socket";
import { getStoredAuthToken } from "lib/utils/auth";
import { getHttpAuthHeader, HttpRequestHeaders } from "lib/utils/https";

// initialize an empty api service that we'll inject endpoints into later as needed
export const rootApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: configuration.backend.url,
    prepareHeaders: (headers, api) => {
      if (["login", "register"].includes(api.endpoint)) {
        return headers;
      }

      const token = getStoredAuthToken();
      const socketId = WebSocketService.getSocketId();

      if (token) {
        headers.set(HttpRequestHeaders.Authorization, getHttpAuthHeader(token));
      }
      if (socketId) {
        headers.set(HttpRequestHeaders.SocketId, socketId);
      }

      return headers;
    },
  }),
  tagTypes: [
    "user",
    "accountsList",
    "accountDetails",
    "spacesList",
    "spaceDetails",
    "spaceDocRegisterRevisions",
    "spaceLists",
    "spaceListDetails",
    "spaceThreads",
    "spaceThreadDetails",
  ],
  endpoints: () => ({}),
});
