import { createRouter, RouterProvider } from "@tanstack/react-router";
import Redirect from "components/Redirect";
import RouterDevTools from "components/RouterDevTools";
import configuration from "configuration";
import { setLogRocketUser } from "lib/monitoring/logrocket";
import { setSentryUser } from "lib/monitoring/sentry";
import { useWebSocket } from "lib/socket";
import LoaderPage from "pages/LoaderPage";
import { FC, Fragment, PropsWithChildren } from "react";
import { useGetProfileQuery } from "redux/services/userApi";
import { routeTree } from "routeTree.gen";

// Modal a new router instance
const router = createRouter({
  routeTree,
  context: {
    user: undefined!,
  },
  notFoundMode: "root",
  defaultPendingComponent: () => <LoaderPage />,
  defaultNotFoundComponent: () => <Redirect to="/" />,
});

type RouterType = typeof router

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: RouterType
  }
}

const App: FC<PropsWithChildren> = () => {
  const { data: user, isFetching } = useGetProfileQuery();
  useWebSocket();

  if (configuration.app.isProduction && user) {
    setSentryUser(user);
    setLogRocketUser(user);
  }

  if (isFetching) {
    return <LoaderPage />;
  }

  return (
    <Fragment>
      <RouterProvider router={router} context={{ user }} />
      <RouterDevTools router={router} />
    </Fragment>
  );
};

export default App;
