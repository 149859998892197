import { FC } from "react";
import { AppLogoContainer } from "components/AppLogo/styled";
import useBreakpoints from "lib/hooks/useBreakpoints";
import { Link } from "@tanstack/react-router";

const AppLogo: FC = () => {
  const { isMobilePortrait } = useBreakpoints();

  return (
    <AppLogoContainer>
      <Link to="/">
        {!isMobilePortrait && (
          <img
            alt="logo"
            src="/airtasks.svg"
            className="logo-desktop"
            height={26}
          />
        )}
        {isMobilePortrait && (
          <img
            alt="logo"
            src="/a-white.svg"
            className="logo-mobile"
            height={26}
          />
        )}
      </Link>
    </AppLogoContainer>
  );
};

export default AppLogo;
