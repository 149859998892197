import { FC, PropsWithChildren } from "react";
import { Talkr as TalkrProvider } from "talkr";

import en from "./locales/en";
import fr from "./locales/fr";

import { AppLocaleCode } from "./lib";
import { useGetProfileQuery } from "redux/services/userApi";
import { LocalStorageKeys, LocalStorageManager } from "lib/utils/localStorage";

interface TalkrProps {
  detectBrowserLanguage?: boolean;
}

const Talkr: FC<PropsWithChildren<TalkrProps>> = (props) => {
  const { children, detectBrowserLanguage = false } = props;
  const { data: user } = useGetProfileQuery();

  const locale = user?.locale
    ?? LocalStorageManager.getItem(LocalStorageKeys.Locale)
    ?? AppLocaleCode.English;

  return (
    <TalkrProvider
      key={locale}
      languages={{ en, fr }}
      defaultLanguage={locale}
      detectBrowserLanguage={detectBrowserLanguage}
    >
      {children}
    </TalkrProvider>
  );
};

export default Talkr;
