import {
  DesktopOutlined,
  MoonOutlined,
  SunOutlined
} from "@ant-design/icons";
import { Segmented, Tooltip } from "antd";
import { LocalStorageKeys } from "lib/utils/localStorage";
import { FC } from "react";
import { TernaryDarkMode, useTernaryDarkMode } from "usehooks-ts";

const ThemeSwitch: FC = () => {
  const {
    ternaryDarkMode,
    setTernaryDarkMode,
  } = useTernaryDarkMode({ localStorageKey: LocalStorageKeys.Theme });

  const segmentValue = ternaryDarkMode ?? "system";

  return (
    <Segmented<TernaryDarkMode>
      value={segmentValue}
      onChange={(value => setTernaryDarkMode(value as TernaryDarkMode))}
      options={[
        {
          value: "light",
          icon: (
            <Tooltip title="Light">
              <SunOutlined />
            </Tooltip>
          )
        },
        {
          value: "dark",
          icon: (
            <Tooltip title="Dark">
              <MoonOutlined />
            </Tooltip>
          )
        },
        {
          value: "system",
          icon: (
            <Tooltip title="System">
              <DesktopOutlined />
            </Tooltip>
          ),
        },
      ]}
    />
  );
};

export default ThemeSwitch;
