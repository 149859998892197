import { useNavigate } from "@tanstack/react-router";
import { Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { FC } from "react";
import { useGetAccountsListQuery } from "redux/services/accountsApi";
import { BarContainerSecondary } from "styles/common";
import useBreakpoints from "lib/hooks/useBreakpoints.ts";

interface AccountSwitcherProps {
  currentKey?: string;
}

const AccountSwitcher: FC<AccountSwitcherProps> = (props) => {
  const breakpoints = useBreakpoints();
  const { currentKey } = props;
  const { data } = useGetAccountsListQuery();
  const navigate = useNavigate();

  const options: DefaultOptionType[] = (data ?? [])?.map(item => ({
    label: item.title,
    value: item.uid,
  }));

  const handleOnChange = (value: string) => {
    navigate({
      to: "/account/$id",
      params: { id: value },
    });
  };

  return (
    <BarContainerSecondary breakpoints={breakpoints}>
      <Select
        value={currentKey}
        options={options}
        onChange={handleOnChange}
        style={{ maxWidth: 200 }}
        dropdownStyle={{ minWidth: 150 }}
      />
    </BarContainerSecondary>
  );
};

export default AccountSwitcher;
