import { LocalStorageKeys, LocalStorageManager } from "lib/utils/localStorage";
import { AppLocaleCode } from "./lib";
import { TParams, tr } from "talkr";
import en from "./locales/en";
import fr from "./locales/fr";
import { TalkrLocaleKey } from "./useTr";

export const getTr = (key: TalkrLocaleKey, params?: TParams) => {
  const languages = { en, fr };
  const getLocale = () => {
    const localStorageKey = LocalStorageManager.getItem(LocalStorageKeys.Locale);

    return localStorageKey
      ? (localStorageKey as AppLocaleCode)
      : AppLocaleCode.English;
  };

  return tr({ locale: getLocale(), languages, defaultLanguage: AppLocaleCode.English }, key, params);
};
