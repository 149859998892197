enum User {
  Status = "status-user",
  Connect = "connect-user",
  Update = "update-user",
}

enum Service {
  Connect = "connect",
  Error = "error",
  Disconnect = "disconnect",
  Reconnect = "reconnect",
  ReconnectAttempt = "reconnect_attempt",
  Reconnecting = "reconnecting",
  ReconnectError = "reconnect_error",
  ReconnectFailed = "reconnect_failed",
}

export const WsEvents = {
  User,
  Service,
};

export interface WsConnectionMessage {
  socketId: string;
  userId: number;
  instance: {
    id: number;
    name: number;
    total: string;
  };
}
