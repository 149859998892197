import { LayoutOutlined, SettingOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Link, useRouterState } from "@tanstack/react-router";
import { Menu } from "antd";
import { useAccountContext } from "lib/context/AccountContext";
import { FC } from "react";
import { useTr } from "lib/i18n";
import useBreakpoints from "lib/hooks/useBreakpoints.ts";

const AccountsNavbar: FC = () => {
  const { T } = useTr();
  const breakpoints = useBreakpoints();

  const router = useRouterState();
  const { value: { data } } = useAccountContext();

  const items = [
    {

      label: <Link to="/account/$id/projects" params={{ id: data?.uid }}>{T("entities.Project.Projects")}</Link>,
      key: "projects",
      icon: <LayoutOutlined />,
    },
    {

      label: <Link to="/account/$id/admins" params={{ id: data?.uid }}>{T("entities.Account.Administrators")}</Link>,
      key: "admins",
      icon: <UsergroupAddOutlined />,
    },
    {

      label: <Link to="/account/$id/settings" params={{ id: data?.uid }}>{T("entities.Account.Settings")}</Link>,
      key: "settings",
      icon: <SettingOutlined />,
    },
  ];

  const currentKeys = items
    .filter(item => router.location.pathname.includes(String(item.key)))
    .map(item => item.key);

  return (
    <Menu
      selectedKeys={currentKeys}
      mode="horizontal"
      items={items}
      style={{
        ...(breakpoints.isDesktop && { padding: "0 34px" }),
      }}
    />
  );
};

export default AccountsNavbar;
