export const LocalStorageKeys = {
  Jwt: "atsk.auth.jwt",
  Theme: "atsk.theme",
  Locale: "atsk.locale",
} as const;

export const LocalStorageManager = {
  setItem: function (key: string, value: unknown): void {
    return localStorage.setItem(key, JSON.stringify(value));
  },

  getItem: function (key: string): string | null {
    if (localStorage.getItem(key) == undefined) {
      return null;
    }

    return JSON.parse(localStorage.getItem(key) || "{}");
  },

  removeItem: function (key: string): void {
    return LocalStorageManager.setItem(key, {});
  },

  clear: function () {
    localStorage.clear();
  }
};
