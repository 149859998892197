import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { captureSentryException } from "lib/monitoring/sentry";
import { JSendResponse, JSendStatus } from "types/jsend";

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(
  error: unknown,
): error is { message: string } {
  return (
    typeof error === "object" &&
    error != null &&
    "message" in error &&
    typeof (error as { message?: string }).message === "string"
  );
}

// TODO: fix this function to support all kinds of errors
export function getFetchBaseErrorMessage(error: unknown) {
  if (isFetchBaseQueryError(error)) {
    const errMsg = "error" in error ? error.error : JSON.stringify(error.data);
    return errMsg;
  } else if (isErrorWithMessage(error)) {
    return error.message;
  } else {
    // @ts-expect-error Treat unknown as ValidateErrorEntity
    return error?.errorFields[0]?.errors[0];
  }
}

export function validateBaseQueryStatus(response: Response, result: JSendResponse | undefined) {
  const isOk = (response?.status === 200) && (result?.status !== JSendStatus.Error);
  console.log("validateBaseQueryResponse", { isOk, response, result });
  return isOk;
}

export function validateBaseQueryInvalidateTags(result: unknown, error: FetchBaseQueryError | undefined, args: object) {
  const isOk = !!result && (error?.data as JSendResponse)?.status !== JSendStatus.Error;
  console.log("validateBaseQueryInvalidateTags", { isOk, result, error, args });
  return isOk;
}

export function validateJSendResponse(response: JSendResponse) {
  if (response.status === JSendStatus.Error) {
    throw new Error(response.message);
  }
  return response;
}

export async function handleApiFormRequest<Res, Err>(
  apiCall: () => Promise<Res>,
  onSuccess?: (res: Res) => unknown,
  onError?: (error: Err) => unknown
) {
  try {
    const res = await apiCall();

    if (onSuccess) {
      onSuccess(res);
    }
  } catch (error) {
    if (onError) {
      onError(error as Err);
    }
    const errorCode = getFetchBaseErrorMessage(error);
    captureSentryException(errorCode, true);
  }
}
