import { notification } from "antd";
import configuration from "configuration";
import { getStoredAuthToken } from "lib/utils/auth";
import BaseClassLoggable from "lib/utils/logger";
import { io, Socket } from "socket.io-client";
import { WsEvents } from "./events";

class WebSocketService extends BaseClassLoggable {
  private socket: Socket | null = null;
  private socketId: string | null = null;
  private initialized: boolean = false;

  /**
   * Establish WebSocket connection
   */
  public connect(): void {
    const jwt = getStoredAuthToken();

    if (!jwt) {
      this.logger.error("JWT token not found. Unable to connect to WebSocket.");
      return;
    }

    this.socket = io(configuration.backend.ws, {
      transports: ["websocket"],
      query: {
        jwt: jwt,
      },
      withCredentials: true,
    });

    this.socket.on(WsEvents.Service.Connect, this._onOpen);
    this.socket.on(WsEvents.Service.Disconnect, this._onClose);
    this.socket.on(WsEvents.Service.Error, this._onError);
  }

  /**
   * Manually disconnect the WebSocket connection
   */
  public disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
      this.initialized = false;
      this.socketId = null;
    }
  }

  /**
   * Handle WebSocket connection opened
   */
  private _onOpen = (): void => {
    this.logger.info("WebSocket connected", this);

    if (configuration.app.isDevelopment && this.initialized) {
      notification.success({
        message: "Connection restored",
        placement: "bottomRight",
      });
    }

    this.initialized = true;
  };

  /**
   * Handle WebSocket disconnection
   */
  private _onClose = (reason: string): void => {
    this.logger.debug("WebSocket disconnected. Reason:", reason);

    if (configuration.app.isDevelopment && reason === "transport close") {
      notification.error({
        message: "Server disconnected",
        placement: "bottomRight",
      });
    }
  };

  /**
   * Send message through WebSocket
   */
  public sendMessage(eventName: string, payload: unknown = {}): void {
    if (this.socket && this.socket.connected) {
      this.socket.emit(eventName, payload);
    } else {
      this.logger.error("Failed to send message. WebSocket is not connected.");
    }
  }

  /**
   * Subscribe to a WebSocket event
   */
  public subscribe<T>(eventName: string, callback?: (data: T) => void): void {
    if (!this.socket) {
      this.logger.error("WebSocket is not connected. Subscription failed.");
      return;
    }

    this.socket.on(eventName, (response: unknown) => {
      const parsedMessage = this._parseMessage(response);
      if (callback) {
        callback(parsedMessage as T);
      }
    });
  }

  /**
   * Unsubscribe from a WebSocket event
   */
  public unsubscribe(eventName: string): void {
    if (this.socket) {
      this.socket.off(eventName);
    }
  }

  /**
   * Get current socket ID
   */
  public getSocketId() {
    return this.socketId;
  }

  /**
   * Set socket ID
   */
  public setSocketId(id: string): void {
    if (configuration.app.isDevelopment) {
      this.logger.debug("Setting socket ID:", id);
    }
    this.socketId = id;
  }

  /**
   * Handle WebSocket errors
   */
  private _onError = (error: unknown): void => {
    this.logger.error("WebSocket encountered an error:", error);
  };

  /**
   * Safely parse incoming messages
   */
  private _parseMessage(message: unknown): unknown {
    if (typeof message === "string") {
      try {
        return JSON.parse(message);
      } catch (e) {
        this.logger.error("Failed to parse WebSocket message:", e);
        return null;
      }
    }
    return message;
  }
}

export default new WebSocketService();
