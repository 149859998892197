import { Autocomplete, TParams, tr, useT } from "talkr";
import { AppLocale } from "./lib";
import { AppLocaleInterface } from "./locales/en";

export type TalkrLocaleKey = Autocomplete<AppLocaleInterface>;

export const useTr = () => {
  const { locale, setLocale, languages, defaultLanguage } = useT();

  const appLocale = Object.values(AppLocale)
    .find(item => item.code === locale)
    ?? AppLocale.English;

  return {
    setLocale,
    locale,
    appLocale,
    T: (key: TalkrLocaleKey, params?: TParams) =>
      tr({ locale, languages, defaultLanguage }, key, params)
  };
};
