import { useEffect } from "react";

import WebSocketService from "./service";
import { WsConnectionMessage, WsEvents } from "./events";
import configuration from "configuration";

const useWebSocket = () => {
  useEffect(() => {
    WebSocketService.connect();

    WebSocketService.subscribe(WsEvents.User.Connect, (data: WsConnectionMessage) => {
      const socketId = data?.socketId;

      if (configuration.app.isDevelopment) {
        console.log(WebSocketService.LogPrefix, "Established connection to:", data.instance.id);
      }

      if (socketId) {
        WebSocketService.setSocketId(socketId);
      }
    });

    return () => WebSocketService.disconnect();
  }, []);
};

export default useWebSocket;
