import { LocalStorageKeys, LocalStorageManager } from "./localStorage";

export function getStoredAuthToken() {
  return LocalStorageManager.getItem(LocalStorageKeys.Jwt);
}

export function setStoredAuthToken(jwt: string | null) {
  if (jwt) {
    LocalStorageManager.setItem(LocalStorageKeys.Jwt, jwt);
  } else {
    LocalStorageManager.removeItem(LocalStorageKeys.Jwt);
  }
}

export function hasStoredAuthToken() {
  return !!LocalStorageManager.getItem(LocalStorageKeys.Jwt);
}
