import { useMediaQuery } from "react-responsive";

const breakpoints = {
  xs: 480,
  // => @media (min-width: 480px) { ... }

  sm: 576,
  // => @media (min-width: 576px) { ... }

  md: 768,
  // => @media (min-width: 768px) { ... }

  lg: 992,
  // => @media (min-width: 992px) { ... }

  xl: 1280,
  // => @media (min-width: 1280px) { ... }

  "xxl": 1536,
  // => @media (min-width: 1536px) { ... }
};

export interface UseBreakpointsState {
  isMobilePortrait: boolean,
  isMobileLandscape: boolean,
  isTablet: boolean,
  isDesktop: boolean,
}

const useBreakpoints = (useMinWidth?: boolean) => {

  const isMobilePortrait = useMediaQuery({
    maxWidth: breakpoints.xs - 1,
  });

  const isMobileLandscape = useMediaQuery({
    ...(useMinWidth && { minWidth: breakpoints.xs }),
    maxWidth: breakpoints.md - 1,
  });

  const isTablet = useMediaQuery({
    ...(useMinWidth && { minWidth: breakpoints.md }),
    maxWidth: breakpoints.lg - 1,
  });

  const isDesktop = useMediaQuery({
    minWidth: breakpoints.lg,
  });

  return {
    isMobilePortrait,
    isMobileLandscape,
    isTablet,
    isDesktop,
  } as UseBreakpointsState;
};

export default useBreakpoints;
